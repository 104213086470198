
.marker_pointer{
  position: absolute;
  bottom: 0px;
  left: 0;
  cursor: pointer;
  background-color: var(--ion-color-light);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-bottom: 16px;
  margin-left: 5px;
  border: 1px solid;
  &:before{
    height: 19px;
    content: " ";
    display: block;
    width: 0;
    border-left: 4px solid var(--ion-color-dark);
    transform: rotate(24deg);
    border-radius: 0 0 50% 50%;
    margin-top: 14px;
    z-index: 2;
    margin-left: -2px;
  }
  .label_containers {
    position: absolute;
    left: 18px;
    padding-left: 2px;
    bottom: -10px;
    display: none;
    label {
      white-space: nowrap;
      cursor: pointer;
      margin-bottom: 2px;
      &.white.current:not(.thema3, .thema4){
        background: #FFF;
      }
      .btn{ margin: 0;}
      .badges{
        position: absolute;
        left: 100%;
        top: 0;
        overflow-x: visible;
        display: flex;
        flex-wrap: wrap;
        width: 200px;
        .badge { 
          width: 2em;
          height: 2em;
          text-align: center;
          border-radius: 50%;
          color: #fff;
          display: flex;
          align-items: center;
          align-content: center;
          flex-wrap: nowrap;
          justify-content: center;
          position: relative;
          margin-right: 2px;
          margin-bottom: 2px;
        }
      }
    }
    label.highlight {
      background-color: var(--ion-color-dark)!important;
      color: var(--ion-color-light)!important;
      border-color: var(--ion-color-dark)!important;
      background-image: none!important;
    }
  }
  .counter{
    display: block;
    position: absolute;
    top: 0px;
    color: var(--ion-color-light);
    right: 4px;
    font-weight: bold;
  }
  &.current.thema3, label.current.thema3{ border-color: var(--li-color-thema3); background-color: var(--li-color-thema3);}
  &.current.thema4, label.current.thema4{ border-color: var(--li-color-thema4); background-color: var(--li-color-thema4);}
  &.current.thema3.thema4, &.current.white:not(.thema3, .thema4){ border-color: var(--ion-color-dark); background-color: var(--ion-color-dark);}
  label.current.thema3.thema4{ border:0;  background: linear-gradient(to bottom right,  var(--li-color-thema4) 50%, var(--li-color-thema3) 50%); }
  &:hover, &.highlight, .map.show_marker_labels &  {
    .label_containers{
      display: block;
    }
    .counter { display: none;}
  }
}
label{
  display:inline-block;
  .marker_pointer &{
    border: 1px solid var(--ion-color-dark);
    border-radius: 2em;
    font-family: var(--ion-font-agipo);
    font-weight: bold;
    padding: 0.2em 0.8em;
    margin-right: 2px;
    white-space: nowrap;
  }
}