@font-face {
  font-family: 'Agipo';
  src: url('agipo_bold.otf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Agipo';
  src: url('agipo_regular_italic.otf');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Agipo';
  src: url('agipo_regular.otf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
