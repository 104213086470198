// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #1569E9;
  --ion-color-primary-rgb: 21, 105, 233;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #125ccd;
  --ion-color-primary-tint: #2c78eb;

  /** secondary **/
  --ion-color-secondary: #FF6800;
  --ion-color-secondary-rgb: 255, 104, 0;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #e05c00;
  --ion-color-secondary-tint: #ff771a;

  /** tertiary **/
    --ion-color-tertiary: #00C2B4;
	--ion-color-tertiary-rgb: 0,194,180;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0,0,0;
	--ion-color-tertiary-shade: #00ab9e;
	--ion-color-tertiary-tint: #1ac8bc;

  /** success **/
  --ion-color-success: #00C34C;
  --ion-color-success-rgb: 0, 195, 76;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #00ac43;
  --ion-color-success-tint: #1ac95e;

  /** warning **/
  --ion-color-warning: #FFB600;
  --ion-color-warning-rgb: 255, 182, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0a000;
  --ion-color-warning-tint: #ffbd1a;

  /** danger **/
    --ion-color-danger: #FC3E15;
	--ion-color-danger-rgb: 252,62,21;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #de3712;
	--ion-color-danger-tint: #fc512c;

  /** dark **/
  --ion-color-dark: #000000;
  --ion-color-dark-rgb: 0, 0, 0;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #1a1a1a;

  /** medium **/
    --ion-color-medium: #4A4A4A;
	--ion-color-medium-rgb: 74,74,74;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255,255,255;
	--ion-color-medium-shade: #414141;
	--ion-color-medium-tint: #5c5c5c;

  /** light **/
  --ion-color-light: #d8d8d8;
  --ion-color-light-rgb: 244, 224, 224;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #c5c5c5;
	--ion-color-light-tint: #e3e3e3;
}

// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */

//   body {
//     --ion-color-primary: #428cff;
//     --ion-color-primary-rgb: 66,140,255;
//     --ion-color-primary-contrast: #ffffff;
//     --ion-color-primary-contrast-rgb: 255,255,255;
//     --ion-color-primary-shade: #3a7be0;
//     --ion-color-primary-tint: #5598ff;

//     --ion-color-secondary: #50c8ff;
//     --ion-color-secondary-rgb: 80,200,255;
//     --ion-color-secondary-contrast: #ffffff;
//     --ion-color-secondary-contrast-rgb: 255,255,255;
//     --ion-color-secondary-shade: #46b0e0;
//     --ion-color-secondary-tint: #62ceff;

//     --ion-color-tertiary: #6a64ff;
//     --ion-color-tertiary-rgb: 106,100,255;
//     --ion-color-tertiary-contrast: #ffffff;
//     --ion-color-tertiary-contrast-rgb: 255,255,255;
//     --ion-color-tertiary-shade: #5d58e0;
//     --ion-color-tertiary-tint: #7974ff;

//     --ion-color-success: #2fdf75;
//     --ion-color-success-rgb: 47,223,117;
//     --ion-color-success-contrast: #000000;
//     --ion-color-success-contrast-rgb: 0,0,0;
//     --ion-color-success-shade: #29c467;
//     --ion-color-success-tint: #44e283;

//     --ion-color-warning: #ffd534;
//     --ion-color-warning-rgb: 255,213,52;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0,0,0;
//     --ion-color-warning-shade: #e0bb2e;
//     --ion-color-warning-tint: #ffd948;

//     --ion-color-danger: #ff4961;
//     --ion-color-danger-rgb: 255,73,97;
//     --ion-color-danger-contrast: #ffffff;
//     --ion-color-danger-contrast-rgb: 255,255,255;
//     --ion-color-danger-shade: #e04055;
//     --ion-color-danger-tint: #ff5b71;

//     --ion-color-dark: #f4f5f8;
//     --ion-color-dark-rgb: 244,245,248;
//     --ion-color-dark-contrast: #000000;
//     --ion-color-dark-contrast-rgb: 0,0,0;
//     --ion-color-dark-shade: #d7d8da;
//     --ion-color-dark-tint: #f5f6f9;

//     --ion-color-medium: #989aa2;
//     --ion-color-medium-rgb: 152,154,162;
//     --ion-color-medium-contrast: #000000;
//     --ion-color-medium-contrast-rgb: 0,0,0;
//     --ion-color-medium-shade: #86888f;
//     --ion-color-medium-tint: #a2a4ab;

//     --ion-color-light: #222428;
//     --ion-color-light-rgb: 34,36,40;
//     --ion-color-light-contrast: #ffffff;
//     --ion-color-light-contrast-rgb: 255,255,255;
//     --ion-color-light-shade: #1e2023;
//     --ion-color-light-tint: #383a3e;
//   }

//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

//   .ios body {
//     --ion-background-color: #000000;
//     --ion-background-color-rgb: 0,0,0;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255,255,255;

//     --ion-color-step-50: #0d0d0d;
//     --ion-color-step-100: #1a1a1a;
//     --ion-color-step-150: #262626;
//     --ion-color-step-200: #333333;
//     --ion-color-step-250: #404040;
//     --ion-color-step-300: #4d4d4d;
//     --ion-color-step-350: #595959;
//     --ion-color-step-400: #666666;
//     --ion-color-step-450: #737373;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #8c8c8c;
//     --ion-color-step-600: #999999;
//     --ion-color-step-650: #a6a6a6;
//     --ion-color-step-700: #b3b3b3;
//     --ion-color-step-750: #bfbfbf;
//     --ion-color-step-800: #cccccc;
//     --ion-color-step-850: #d9d9d9;
//     --ion-color-step-900: #e6e6e6;
//     --ion-color-step-950: #f2f2f2;

//     --ion-item-background: #000000;

//     --ion-card-background: #1c1c1d;
//   }

//   .ios ion-modal {
//     --ion-background-color: var(--ion-color-step-100);
//     --ion-toolbar-background: var(--ion-color-step-150);
//     --ion-toolbar-border-color: var(--ion-color-step-250);
//   }


//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

//   .md body {
//     --ion-background-color: #121212;
//     --ion-background-color-rgb: 18,18,18;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255,255,255;

//     --ion-border-color: #222222;

//     --ion-color-step-50: #1e1e1e;
//     --ion-color-step-100: #2a2a2a;
//     --ion-color-step-150: #363636;
//     --ion-color-step-200: #414141;
//     --ion-color-step-250: #4d4d4d;
//     --ion-color-step-300: #595959;
//     --ion-color-step-350: #656565;
//     --ion-color-step-400: #717171;
//     --ion-color-step-450: #7d7d7d;
//     --ion-color-step-500: #898989;
//     --ion-color-step-550: #949494;
//     --ion-color-step-600: #a0a0a0;
//     --ion-color-step-650: #acacac;
//     --ion-color-step-700: #b8b8b8;
//     --ion-color-step-750: #c4c4c4;
//     --ion-color-step-800: #d0d0d0;
//     --ion-color-step-850: #dbdbdb;
//     --ion-color-step-900: #e7e7e7;
//     --ion-color-step-950: #f3f3f3;

//     --ion-item-background: #1e1e1e;

//     --ion-toolbar-background: #1f1f1f;

//     --ion-tab-bar-background: #1f1f1f;

//     --ion-card-background: #1e1e1e;
//   }
// }
:root{
  --li-color-thema1: #ffb600;
	--ion-color-thema1: var(--li-color-thema1);
	--ion-color-thema1-rgb: 255, 182, 0;
	--ion-color-thema1-contrast: #000000;
	--ion-color-thema1-contrast-rgb: 0,0,0;
	--ion-color-thema1-shade: #000000;
	--ion-color-thema1-tint: var(--li-color-thema1);

  --li-color-thema2: #00c34b;
	--ion-color-thema2:var(--li-color-thema2) ;
	--ion-color-thema2-rgb: 255, 182, 0;
	--ion-color-thema2-contrast: var(--li-color-thema2);
	--ion-color-thema2-contrast-rgb: 0, 195, 75;
	--ion-color-thema2-shade: var(--li-color-thema2);
	--ion-color-thema2-tint: var(--li-color-thema2);

  --li-color-thema3: #00c1b4;
	--ion-color-thema3: var(--li-color-thema3);
	--ion-color-thema3-rgb: 0, 193, 180;
	--ion-color-thema3-contrast: var(--li-color-thema3);
	--ion-color-thema3-contrast-rgb: 0, 193, 180;
	--ion-color-thema3-shade: var(--li-color-thema3);
	--ion-color-thema3-tint: var(--li-color-thema3);
  
  --li-color-thema4: rgb(255, 104, 0);
	--ion-color-thema4: var(--li-color-thema4);
	--ion-color-thema4-rgb: 255, 104, 0;
	--ion-color-thema4-contrast: var(--li-color-thema4);
	--ion-color-thema4-contrast-rgb: 255, 104, 0;
	--ion-color-thema4-shade: var(--li-color-thema4);
	--ion-color-thema4-tint: var(--li-color-thema4);
  

}

.ion-color-thema1 {
	--ion-color-base: var(--ion-color-thema1);
	--ion-color-base-rgb: var(--ion-color-thema1-rgb);
	--ion-color-contrast: var(--ion-color-thema1-contrast);
	--ion-color-contrast-rgb: var(--ion-color-thema1-contrast-rgb);
	--ion-color-shade: var(--ion-color-thema1-shade);
	--ion-color-tint: var(--ion-color-thema1-tint);
  --color: var(--li-color-thema1);

}

.ion-color-thema2 {
	--ion-color-base: var(--ion-color-thema2);
	--ion-color-base-rgb: var(--ion-color-thema2-rgb);
	--ion-color-contrast: var(--ion-color-thema2-contrast);
	--ion-color-contrast-rgb: var(--ion-color-thema2-contrast-rgb);
	--ion-color-shade: var(--ion-color-thema2-shade);
	--ion-color-tint: var(--ion-color-thema2-tint);
  --color: var(--li-color-thema2);

}

.ion-color-thema3 {
	--ion-color-base: var(--li-color-thema3);
	--ion-color-base-rgb: var(--ion-color-thema3-rgb);
	--ion-color-contrast: var(--ion-color-thema3-contrast);
	--ion-color-contrast-rgb: var(--ion-color-thema3-contrast-rgb);
	--ion-color-shade: var(--ion-color-thema3-shade);
	--ion-color-tint: var(--ion-color-thema3-tint);
  --color: var(--li-color-thema3);

}

.ion-color-thema4 {
	--ion-color-base: var(--li-color-thema4);
	--ion-color-base-rgb: var(--ion-color-thema4-rgb);
	--ion-color-contrast: var(--ion-color-thema4-contrast);
	--ion-color-contrast-rgb: var(--ion-color-thema4-contrast-rgb);
	--ion-color-shade: var(--ion-color-thema4-shade);
	--ion-color-tint: var(--ion-color-thema4-tint);
  --color: var(--li-color-thema4);

}
