/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import './assets/fonts/agipo/style.css';
@import './assets/fonts/noto-sans/style.css';

@import '~swiper/scss';
@import '~swiper/scss/autoplay';
@import '~swiper/scss/keyboard';
@import '~swiper/scss/pagination';
@import '~swiper/scss/navigation';
@import '~swiper/scss/scrollbar';
@import '~swiper/scss/zoom';
@import '~@ionic/angular/css/ionic-swiper';

@import '~leaflet/dist/leaflet.css';
@import 'styles.scss';
@import './theme/map';

:root {
  --ion-font-agipo: 'Agipo', 'Helvetica Neue', sans-serif;
  --ion-font-nodo: 'Nodo Sans', 'Helvetica Neue', sans-serif;
  --ion-font-family: var(--ion-font-agipo);
  --ion-text-color: var(--ion-color-medium);
}

.font-agipo {
  --ion-font-family: var(--ion-font-agipo);
}
.font-nodo {
  --ion-font-family: var(--ion-font-nodo);
}

img, ion-img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: var(--ion-font-agipo);
}

h1 {
  font-size: 31px;
  font-weight: 400;
  font-style: italic;
  line-height: 1.1;
}

h2 {
  font-size: 26px;
  font-style: italic;
  font-weight: 400;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}



.icon-superlarge {
  font-size: 128px;
}



ion-button{
  &.button-solid.ion-color-warning {
    --ion-color-warning-contrast: #4a4a4a;
  }
  &.text-transform-none {
    text-transform: none;
  }
  &.button-outline {
    --ion-font-family: var(--ion-font-agipo);
    font-weight: 400;
    min-width: 152px;
    height: 40px;
    min-height: 40px;
    line-height: 40px;
  }
}

ion-header {
  ion-toolbar {
    --min-height: 60px;
    --margin-top: 35px;
    --margin-start: 20px;
    --margin-end: 20px;
    ion-title {
      --ion-font-family: var(--ion-font-agipo);
      text-transform: uppercase;
      font-weight: 400;
      font-size: 18px;
      letter-spacing: 0.69px;
    }

    .sc-ion-buttons-ios-s ion-button,
    ion-button {
      color: #d8d8d8;
      font-size: 22px;
    }
  }
}

ion-footer {
  ion-button {
    box-shadow: none;
    --box-shadow: none;
  }
  ion-toolbar {
    --min-height: 60px;

    ion-button {
      --ion-font-family: var(--ion-font-agipo);
      text-transform: uppercase;
      font-weight: 400;
      font-size: 18px;
      letter-spacing: 0.69px;
      margin: 0;
      min-height: var(--min-height);
    }
  }
}





input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none!important;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield!important;
}


ion-modal.auto-height { 
  --max-height: 444px;  
}




//TODO move to home-locations.page or remove it
.searchbar-wrap {
  position: relative;

  ion-searchbar {
    padding: 0;
    --box-shadow: none;
    --border-radius: 0;
    --background: transparent;
    border-bottom: 2px solid #fff;
    --padding-start: 0;
    --color: #fff;
    --icon-color: #fff;
    --placeholder-opacity: 1;

    &.searchbar-has-focus {
      --placeholder-opacity: 0.5;
    }
  }

  .searchbar-input.sc-ion-searchbar-md {
    padding-inline-start: 0;
    padding-left: 0;
    padding-right: 36px;
  }

  .searchbar-search-icon.sc-ion-searchbar-md {
    left: auto;
    right: 6px;
    display: none !important;
  }

  & > ion-button {
    position: absolute;
    top: -2px;
    right: 0;
    color: #fff;
    margin: 0;
    --padding-start: 0;
    --padding-end: 0;
    width: 34px;
  }
}



.sec-title {
  margin: 20px 0;
  padding-left: 30px;
  padding-right: 30px;
  p {
    margin: 20px 0;
  }
}



ion-tabs {
  ion-content {
    --background: #f6f6f6;
  }
}

ion-card {
  &.collapsible {
    border-radius: 6px;
    box-shadow: 0 1px 5px rgba(32, 27, 55, 0.19);
    margin: 20px;
    border: 1px solid rgba(74, 74, 74, 0.1);
    color: #000000;

    .card-header {
      margin: 0;
      height: auto;
      white-space: normal;
      text-align: left;
      text-transform: inherit;
      --padding-start: 0;
      --padding-end: 0;
      --padding-top: 0;
      --padding-bottom: 0;
      --background: transparent;
      --background-focused: transparent;
      --background-hover: transparent;
      --background-activated: rgba(36, 38, 51, 0.1);
      --box-shadow: none;
      --border-radius: 0;
      width: 100%;

      .btn-wrap {
        display: flex;
        padding: 0;
        color: #000;
        min-height: 83px;
        width: 100%;
        pointer-events: none;

        .count {
          flex: 0 0 45px;
          width: 45px;
          border-right: 1px solid rgba(36, 38, 51, 0.1);
          font-size: 18px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .title {
          padding: 4px 15px;
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.62px;
          font-family: var(--ion-font-agipo);
          flex: auto;
        }

        .right {
          text-align: right;
          padding-right: 11px;
          padding-top: 20px;
          flex: 0 0 26px;

          & > ion-icon {
            font-size: 20px;
            transition: all 0.3s;
          }

          .status {
            color: #fff;
            margin-top: 4px;
            display: flex;
            align-items: center;

            span {
              font-size: 10px;
              margin-right: 7px;
            }

            ion-icon {
              font-size: 26px;
              flex: 0 0 26px;
            }
          }
        }
      }
    }

    & > ion-card-content {
      padding: 0;
      height: 0;
      overflow: hidden;
      transition: all 0.3s;
      border-top: 0px solid rgba(36, 38, 51, 0.1);

    }

    &.expanded {
      .card-header .btn-wrap .right > ion-icon {
        transform: rotate(180deg);
      }
      
      ion-card-content {
        height: auto;
        border-top-width: 1px;
      }
    }
  }

  &.simple-card {
    margin: 0;
    width: 100%;
    border: 1px solid rgba(74, 74, 74, 0.1);
    box-shadow: 0 1px 5px rgba(32, 27, 55, 0.19);
    border-radius: 6px;
    margin: 10px;

    ion-card-header {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      padding-top: 10px;

      ion-button {
        color: #d8d8d8;
        font-size: 22px;
        --padding-end: 0;
        --padding-start: 0;
        margin: 0;
      }

      h5 {
        margin: 0;
        color: #000000;
        font-size: 14px;
      }
    }

    .card-img {
      min-height: 125px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 10px;
    }

    .card-footer {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 5px;
      margin-top: 30px;

      & > ion-button {
        margin: 0;
        font-size: 14px;
        text-transform: inherit;
        --padding-end: 0;
        --padding-start: 0;
        --ion-color-medium: rgba(135, 138, 131, 0.6);
      }
    }
  }
}


.swiper {
  padding-bottom: 45px;
  --bullet-background: #fff;
  --bullet-background-active: #000;

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    opacity: 1;
    border: 1px solid;
  }
}

.swiper-slide:nth-child(n) {
  width: 85%;
}


ion-toggle {
  width: 64px;
  height: 29px;
  --handle-height: 20px;
  --handle-width: 20px;
  --handle-spacing: 5px;
  --background: #d8d8d8;

  &:after {
    content: 'nein';
    display: inline-block;
    position: absolute;
    right: 8px;
    top: 47%;
    font-size: 12px;
    transform: translate(0px, -50%);
    color: #4a4a4a;
    opacity: 0;
    visibility: hidden;
  }

  &::before {
    content: 'ja';
    display: inline-block;
    position: absolute;
    left: 12px;
    top: 47%;
    font-size: 12px;
    color: #fff;
    transform: translate(0px, -50%);
    opacity: 0;
    visibility: hidden;
    z-index: 1;
  }

  &[aria-checked='false'] {
    &:after {
      visibility: visible;
      opacity: 1;
    }
  }

  &[aria-checked='true'] {
    &::before {
      visibility: visible;
      opacity: 1;
    }
  }
}

.cus-chekbox {
  ion-item,
  .item {
    --padding-end: 0;
    --padding-start: 0;
    --inner-padding-end: 0;
    height: auto;
    --min-height: 0;

    .sc-ion-label-md-h,
    .sc-ion-label-md-h,
    ion-label {
      white-space: normal;
      font-size: 12px;
      color: #4a4a4a;
      margin: 0;
    }

    ion-checkbox, ion-radio {
      margin-right: 8px;
      width: 26px;
      height: 26px;
      --border-color: rgba(74, 74, 74, 0.23);
      --background: rgba(74, 74, 74, 0.04);
      --background-checked: rgba(74, 74, 74, 0.04);
      --checkmark-color: rgba(74, 74, 74, 1);
      --border-color-checked: rgba(74, 74, 74, 1);
      --checkmark-width: 2px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
ion-range {
  padding: 0 10px;
  --knob-size: 26px;
  --bar-height: 5px;
  --bar-border-radius: 50px;
}

ion-icon {
  font-size: 24px;
}

.screen-icon{ 
  max-width: 150px;
  margin:auto;
  padding: 30px 0px;
}
.form-error{
  color: var(--ion-color-danger);
}
.bold{
  font-weight: bold;
}