.my_position {
  .marker {
    background-color: #4285f4;
    display: block;
    width: 100%;
    border: 1px solid #fff;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0 0 4px 3px #4285f4;
  }
}

.label-container {
  position: absolute;

  .map-label {
    border: 1px solid #4285f4;
    color: #4285f4;
    background-color: #fff;
    border-radius: 2em;
    display: inline-block;
    padding: 0.2em 0.4em;
    position: relative;
    left: -50%;
    top: -1.5em;
    text-wrap: nowrap;
    white-space: nowrap;
    &.hollow:not(.focus) {
      background-color: transparent;
    }
  }
}
ion-title {
  .entity-name {
    text-transform: none;
  }
}
