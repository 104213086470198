@font-face {
    font-family: 'Noto Sans';
    src: url('NotoSans-BoldItalic.woff') format('woff'),
        url('NotoSans-BoldItalic.ttf') format('truetype'),
        url('NotoSans-BoldItalic.svg#NotoSans-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('NotoSans-Italic.woff') format('woff'),
        url('NotoSans-Italic.ttf') format('truetype'),
        url('NotoSans-Italic.svg#NotoSans-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('NotoSans-Bold.woff') format('woff'),
        url('NotoSans-Bold.ttf') format('truetype'),
        url('NotoSans-Bold.svg#NotoSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('NotoSans.woff') format('woff'),
        url('NotoSans.ttf') format('truetype'),
        url('NotoSans.svg#NotoSans') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

